.base {
  @apply relative border bg-white rounded-md transition-all duration-100;

  .statusColor {
    @apply transition-colors duration-100;
  }
}

.rounded {
  @apply rounded-full;
}

.default {
  @apply border-gray-400;

  .statusBorderColor {
    @apply border-gray-400;
  }

  .statusBgColor {
    @apply bg-gray-400;
  }

  &:hover {
    @apply border-gray-600;

    .statusBorderColor {
      @apply border-gray-600;
    }

    .statusBgColor {
      @apply bg-gray-600;
    }
  }

  &:focus-within {
    @apply border-theme-primary;
    box-shadow: 0 0 0 1px theme('colors.theme.primary');

    .statusBorderColor {
      @apply border-theme-primary;
    }

    .statusBgColor {
      @apply bg-theme-primary;
    }
  }
}

.error {
  @apply border-red-600;
  box-shadow: 0 0 0 1px theme('colors.red.600');

  .statusColor {
    @apply text-red-600;
  }

  .statusBorderColor {
    @apply border-red-600;
  }

  .statusBgColor {
    @apply bg-red-600;
  }
}

.warning {
  @apply border-yellow-600;
  box-shadow: 0 0 0 1px theme('colors.yellow.600');

  .statusColor {
    @apply text-yellow-600;
  }

  .statusBorderColor {
    @apply border-yellow-600;
  }

  .statusBgColor {
    @apply bg-yellow-600;
  }
}

.success {
  @apply border-green-500;
  box-shadow: 0 0 0 1px theme('colors.green.500');

  .statusColor {
    @apply text-green-500;
  }

  .statusBorderColor {
    @apply border-green-500;
  }

  .statusBgColor {
    @apply bg-green-500;
  }
}

.info {
  @apply border-theme-primary;
  box-shadow: 0 0 0 1px theme('colors.theme.primary');

  .statusColor {
    @apply text-theme-primary;
  }

  .statusBorderColor {
    @apply border-theme-primary;
  }

  .statusBgColor {
    @apply bg-theme-primary;
  }
}

.readonly,
.disabled {
  @apply border-gray-400 bg-gray-300;

  .statusBorderColor {
    @apply border-gray-400;
  }

  .statusBgColor {
    @apply bg-gray-400;
  }
}

.disabled {
  @apply text-gray-500;
}
